var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"custom":"","to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.DASHBOARD')))])])])]}}])}),_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin|worker'),expression:"'admin|worker'",arg:"any"}],staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.COMPANIES_AND_USERS')))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]),_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin|worker'),expression:"'admin|worker'",arg:"any"}],staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-active': _vm.hasActiveChildren([
        '/companies/list',
        '/companies/new',
        '/companies/edit',
        '/companies/daily-revenue',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Clothes/Briefcase.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.COMPANIES')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"custom":"","to":"/companies/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.MY_COMPANIES')))])])])]}}])}),(_vm.currentUserRole === 'ROLE_SUPER_ADMIN')?_c('router-link',{attrs:{"custom":"","to":"/companies/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.ALL_COMPANIES')))])])])]}}],null,false,1550412731)}):_vm._e(),(_vm.currentActiveModuleComponents?.company.dailyRevenue)?_c('router-link',{attrs:{"custom":"","to":"/companies/daily-revenue"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.DAILY_REVENUE')))])])])]}}],null,false,1594845816)}):_vm._e()],1)])]),_c('li',{directives:[{name:"role",rawName:"v-role",value:('admin'),expression:"'admin'"}],staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-active': _vm.hasActiveChildren([
        '/users/list',
        '/users/new',
        '/users/edit',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/User.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.USERS')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"custom":"","to":"/users/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.ALL_USERS')))])])])]}}])})],1)])]),_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin|junior|worker|watcher'),expression:"'admin|junior|worker|watcher'",arg:"any"}],staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.LOGGERS_AND_DEVICES')))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]),_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin|junior|worker|watcher'),expression:"'admin|junior|worker|watcher'",arg:"any"}],staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-active': _vm.hasActiveChildren([
        '/devices/list',
        '/devices/new',
        '/devices/edit',
        '/devices/service-mode-history',
        '/devices/critical-history',
        '/devices/free-ride',
        '/devices/reset',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Electric/Outlet.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.DEVICES')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"custom":"","to":"/devices/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.ALL_DEVICES')))])])])]}}])}),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('router-link',{directives:[{name:"role",rawName:"v-role:any",value:('admin|junior|worker'),expression:"'admin|junior|worker'",arg:"any"}],attrs:{"custom":"","to":"/devices/free-ride"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Send Free Ride")])])])]}}],null,false,1636303171)}):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('router-link',{directives:[{name:"role",rawName:"v-role:any",value:('admin|junior|worker'),expression:"'admin|junior|worker'",arg:"any"}],attrs:{"custom":"","to":"/devices/reset"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('GENERAL.RESET')))])])])]}}],null,false,365365728)}):_vm._e()],1)])]),(
      _vm.currentUserCompanyModule[0] === 'gaming' &&
      _vm.currentActiveModuleComponents?.devices.maintenance
    )?_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin|junior|worker'),expression:"'admin|junior|worker'",arg:"any"}],staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-active': _vm.hasActiveChildren([
        '/devices-maintenance/device',
        '/devices-maintenance/range',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Tools/Tools.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.MAINTENANCE')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"custom":"","to":"/devices-maintenance/device"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.MAINTENANCE_BY_DEVICE')))])])])]}}],null,false,1199677114)}),_c('router-link',{attrs:{"custom":"","to":"/devices-maintenance/range"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.MAINTENANCE_IN_RANGE')))])])])]}}],null,false,2036443777)})],1)])]):_vm._e(),(_vm.currentActiveModuleComponents?.devices.map)?_c('router-link',{attrs:{"custom":"","to":"/devices/locations"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin|junior|worker'),expression:"'admin|junior|worker'",arg:"any"}],staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Map/Marker2.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.DEVICES_MAP')))])])])]}}],null,false,1793501548)}):_vm._e(),_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin|worker'),expression:"'admin|worker'",arg:"any"}],staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.CITIES_LESSORS_AND_TAGS')))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]),(_vm.currentUserRole === 'ROLE_SUPER_ADMIN')?_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin'),expression:"'admin'",arg:"any"}],staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-active': _vm.hasActiveChildren([
        '/cities/list',
        '/cities/new',
        '/cities/edit',
      ]),
    }},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Home/Building.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.CITIES')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"custom":"","to":"/cities/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.ALL_CITIES')))])])])]}}],null,false,310874139)}),_vm._v(" <!– "),_c('router-link',{attrs:{"custom":"","to":"/cities/new"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.ADD_NEW_CITY')))])])])]}}],null,false,3193810302)}),_vm._v(" –> ")],1)])]):_vm._e(),(
      _vm.currentUserCompanyModule[0] === 'gaming' &&
      _vm.currentActiveModuleComponents?.location.lessors
    )?_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin|worker'),expression:"'admin|worker'",arg:"any"}],staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-active': _vm.hasActiveChildren([
        '/lessors/list',
        '/lessors/new',
        '/lessors/edit',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Shopping/Wallet.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.LESSORS')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"custom":"","to":"/lessors/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.ALL_LESSORS')))])])])]}}],null,false,1725430589)}),(_vm.currentUserRole === 'ROLE_SUPER_ADMIN')?_c('router-link',{attrs:{"custom":"","to":"/lessors/new"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.ADD_NEW_LESSOR')))])])])]}}],null,false,3026000237)}):_vm._e()],1)])]):_vm._e(),(_vm.currentActiveModuleComponents?.location.locations)?_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin|worker'),expression:"'admin|worker'",arg:"any"}],staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-active': _vm.hasActiveChildren([
        '/locations/list',
        '/locations/new',
        '/locations/edit',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Map/Position.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.LOCATIONS')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"custom":"","to":"/locations/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.ALL_LOCATIONS')))])])])]}}],null,false,2261565972)})],1)])]):_vm._e(),_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.REPORTS')))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]),(_vm.currentUserRole === 'ROLE_SUPER_ADMIN')?_c('router-link',{directives:[{name:"role",rawName:"v-role:any",value:('admin|junior|worker'),expression:"'admin|junior|worker'",arg:"any"}],attrs:{"custom":"","to":"/transactions-bills/comparison"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Design/Color-profile.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.TRANSACTIONS_BILLS_COMPARISON')))])])])]}}],null,false,3453787702)}):_vm._e(),_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin|junior|worker'),expression:"'admin|junior|worker'",arg:"any"}],staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-active': _vm.hasActiveChildren(['/deposits/list']),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Shopping/Euro.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.DEPOSITS')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"custom":"","to":"/deposits/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.VIEW')))])])])]}}])})],1)])]),(_vm.currentActiveModuleComponents?.reports.detailedReview)?_c('router-link',{directives:[{name:"role",rawName:"v-role:any",value:('admin|junior|worker'),expression:"'admin|junior|worker'",arg:"any"}],attrs:{"custom":"","to":"/detailed-review/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Design/Color-profile.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.DETAILED_REVIEW')))])])])]}}],null,false,2049669909)}):_vm._e(),(_vm.currentActiveModuleComponents?.reports.todayRevenue)?_c('router-link',{directives:[{name:"role",rawName:"v-role:any",value:('admin|worker'),expression:"'admin|worker'",arg:"any"}],attrs:{"custom":"","to":"/today-revenue/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Shopping/Money.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.TODAY_REVENUE')))])])])]}}],null,false,4129887215)}):_vm._e(),_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin|watcher|worker'),expression:"'admin|watcher|worker'",arg:"any"}],staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-active': _vm.hasActiveChildren([
        '/device-report/device',
        '/device-report/device-new',
        '/location-report/location',
        '/location-report/company',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Shopping/Chart-bar2.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.FINANCE_REPORTS')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"custom":"","to":"/finance-report/device"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.FINANCE_REPORTS_BY_DEVICES')))])])])]}}])}),_c('router-link',{directives:[{name:"role",rawName:"v-role:any",value:('admin|worker'),expression:"'admin|worker'",arg:"any"}],attrs:{"custom":"","to":"/finance-report/location"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.FINANCE_REPORTS_BY_LOCATIONS')))])])])]}}])}),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('router-link',{directives:[{name:"role",rawName:"v-role:any",value:('admin|worker'),expression:"'admin|worker'",arg:"any"}],attrs:{"custom":"","to":"/finance-report/lessor"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.FINANCE_REPORTS_BY_LESSOR')))])])])]}}],null,false,3687310785)}):_vm._e()],1)])]),_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin|watcher|worker'),expression:"'admin|watcher|worker'",arg:"any"}],staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-active': _vm.hasActiveChildren([
        '/token-report/device',
        '/token-report/location',
        '/token-report/lessor',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Shopping/Chart-bar2.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.TOKEN_REPORTS')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"custom":"","to":"/token-report/device"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.FINANCE_REPORTS_BY_DEVICES')))])])])]}}])}),_c('router-link',{directives:[{name:"role",rawName:"v-role:any",value:('admin|worker'),expression:"'admin|worker'",arg:"any"}],attrs:{"custom":"","to":"/token-report/location"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.FINANCE_REPORTS_BY_LOCATIONS')))])])])]}}])}),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('router-link',{directives:[{name:"role",rawName:"v-role:any",value:('admin|worker'),expression:"'admin|worker'",arg:"any"}],attrs:{"custom":"","to":"/token-report/lessor"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.FINANCE_REPORTS_BY_LESSOR')))])])])]}}],null,false,3687310785)}):_vm._e()],1)])]),(_vm.currentActiveModuleComponents?.reports.comparativeAnalysis)?_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin|worker'),expression:"'admin|worker'",arg:"any"}],staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-active': _vm.hasActiveChildren([
        '/comparative-analysis/device',
        '/comparative-analysis/location',
        '/comparative-analysis/lessor',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Design/Difference.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.COMPARATIVE_ANALYSIS')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"custom":"","to":"/comparative-analysis/device"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.COMPARATIVE_ANALYSIS_BY_DEVICES')))])])])]}}],null,false,4029370516)}),_c('router-link',{attrs:{"custom":"","to":"/comparative-analysis/location"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.COMPARATIVE_ANALYSIS_BY_LOCATIONS')))])])])]}}],null,false,4106685393)}),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('router-link',{attrs:{"custom":"","to":"/comparative-analysis/lessor"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.COMPARATIVE_ANALYSIS_BY_LESSOR')))])])])]}}],null,false,1961516203)}):_vm._e()],1)])]):_vm._e(),(_vm.currentUserPersonalInfo.email.includes('@arsfutura.co'))?_c('router-link',{directives:[{name:"role",rawName:"v-role:any",value:('admin'),expression:"'admin'",arg:"any"}],attrs:{"custom":"","to":"/ebitda/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Design/Color-profile.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("EBITDA")])])])]}}],null,false,260902026)}):_vm._e(),_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin|worker'),expression:"'admin|worker'",arg:"any"}],staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.TRAFIC')))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]),(_vm.currentActiveModuleComponents?.traffic.transactions)?_c('router-link',{directives:[{name:"role",rawName:"v-role:any",value:('admin|worker'),expression:"'admin|worker'",arg:"any"}],attrs:{"custom":"","to":"/transactions/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Shopping/Barcode.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.TRANSACTIONS')))])])])]}}],null,false,2362429750)}):_vm._e(),_c('li',{directives:[{name:"role",rawName:"v-role:any",value:('admin|worker'),expression:"'admin|worker'",arg:"any"}],staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-active': _vm.hasActiveChildren([
        '/bills/list',
        '/bills/filter',
        '/bills/new',
        '/bills/request',
        '/bills/unfiscalized',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Shopping/Barcode-read.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.BILLS')))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"custom":"","to":"/bills/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.ALL_BILLS')))])])])]}}])}),(_vm.currentActiveModuleComponents?.bills.filterBills)?_c('router-link',{attrs:{"custom":"","to":"/bills/filter"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Filter Bills")])])])]}}],null,false,2468820585)}):_vm._e(),(_vm.currentActiveModuleComponents?.bills.createNewBillPdf)?_c('router-link',{attrs:{"custom":"","to":"/bills/new"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Create Bill PDF")])])])]}}],null,false,3656403852)}):_vm._e(),_c('router-link',{attrs:{"custom":"","to":"/bills/request"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.NEW_BILL')))])])])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/bills/unfiscalized"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Unfiscalized")])])])]}}])})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }